<template>
  <div class="services">
    <div class="sec1 pb-5">
      <div class="container">
        <div class="row px-5 px-md-0">
          <div class="col-md-6">
            <h2 class="black">Наши <span class="red">услуги</span></h2>
            <p>
              Поможем получить банковскую карту, оформить ВНЖ, приобрести недвижимость, купить товар, заказать экскурсию
            </p>
          </div>
          <div class="col-md-6 d-flex justify-content-center align-items-center">
            <router-link :to="{name: 'FormPage', params:{formType: 'services'}}" class="btn mt-4">Получить помощь <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="sec2 py-5">
      <div class="container">
        <div class="sliderServices1">
          <div  v-for="(slide, i) in slides" :key="i">
            <div class="s2_slide pb-5 px-3 d-flex flex-column justify-content-end">
              <div>
                <img :src="slide.img" alt="">
              </div>
              <div class="black fw-bold pt-3 text-center">{{ slide.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sec3 py-5">
      <div class="container py-4">
        <h3 class="black text-center">Как заказать услугу</h3>
        <div class="row d-lg-flex d-none">
          <div class="col-6">
            <div class="s3_services_el s3_services_el1 my-3">
              <div class="icon"><img src="img/3-icon1.png" alt=""></div>
              <h4 class="black pt-5">Выберите услугу <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
              <p>Персональный шопинг, Оформление ВНЖ,
                Открытие банковского счета,
                Трансфер, Прокат авто,
                Заказ экскурсий, другое</p>

            </div>
          </div>
          <div class="col-6">
            <div class="s3_services_el s3_services_el2 my-3">
              <div class="icon"><img src="img/3-icon2.png" alt=""></div>
              <h4 class="black pt-5">Опишите подробности в комментарии  <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
              <p>Сообщите ваше местонахождение, сроки
                и другую важную информацию</p>

            </div>
          </div>
          <div class="col-6">
            <div class="s3_services_el s3_services_el3 my-3">
              <div class="icon"><img src="img/3-icon3.png" alt=""></div>
              <h4 class="black pt-5">Мы свяжемся с вами <i><img src="img/arrow-red-24-px.svg" alt=""></i>
              </h4>
              <p>Уточним детали и рассчитаем стоимость</p>

            </div>
          </div>
          <div class="col-6">
            <div class="s3_services_el s3_services_el4 my-3">
              <div class="icon"><img src="img/3-icon4.png" alt=""></div>
              <h4 class="black pt-5">Оплатите услугу <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
              <p>На сайте или непосредственно нашему сотруднику при встрече через мобильный терминал
                <span class="d-block">
                  К оплате принимаются любые российские банковские карты
                (МИР, Visa, MasterCard), сумма списывается в рублях.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="d-lg-none d-block">
          <div class="sliderServices2">
           <div> <div class="s3_services_el s3_services_el1 my-3">
             <div class="icon"><img src="img/3-icon1.png" alt=""></div>
             <h4 class="black pt-5">Выберите услугу <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
             <p>Персональный шопинг, Оформление ВНЖ,
               Открытие банковского счета,
               Трансфер, Прокат авто,
               Заказ экскурсий, другое</p>

           </div></div>
           <div><div class="s3_services_el s3_services_el2 my-3">
             <div class="icon"><img src="img/3-icon2.png" alt=""></div>
             <h4 class="black pt-5">Опишите подробности в комментарии  <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
             <p>Сообщите ваше местонахождение, сроки
               и другую важную информацию</p>

           </div></div>
           <div><div class="s3_services_el s3_services_el3 my-3">
             <div class="icon"><img src="img/3-icon3.png" alt=""></div>
             <h4 class="black pt-5">Мы свяжемся с вами <i><img src="img/arrow-red-24-px.svg" alt=""></i>
             </h4>
             <p>Уточним детали и рассчитаем стоимость</p>

           </div></div>
           <div> <div class="s3_services_el s3_services_el4 my-3">
             <div class="icon"><img src="img/3-icon4.png" alt=""></div>
             <h4 class="black pt-5">Оплатите услугу <i><img src="img/arrow-red-24-px.svg" alt=""></i></h4>
             <p>На сайте или непосредственно нашему сотруднику при встрече через мобильный терминал
               <span class="d-block">
                  К оплате принимаются любые российские банковские карты
                (МИР, Visa, MasterCard), сумма списывается в рублях.
                </span>
             </p>
           </div></div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center mt-5 pt-3">
          <router-link :to="{name: 'FormPage', params:{formType: 'services'}}" class="btn">Заказать услугу <i><img src=img/arrow-forward-24-px.svg alt=""></i></router-link>
        </div>
      </div>
    </div>
    <div class="sec4">
      <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex justify-content-center align-items-center h-100">
          <img class="d-md-none d-inline-block" src="img/4-bg-mob.png" alt="">
          <img class="d-none d-md-inline-block" src="img/4-bg.png" alt="">
          </div>
        </div>
        <div class="col-md-6">
          <div class="d-flex justify-content-center align-items-center h-100 p-5">
            <h3 class="black">Услугу оказывают русскоговорящие специалисты</h3>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "Services",
  components: {
  },
  data() {
    return {
      slides: [
        {
          img: 'img/2-slide-1.png',
          text: 'Оформление документов и карт'
        },
        {
          img: 'img/2-slide-2.png',
          text: 'Экскурсии и путешествия'
        },
        {
          img: 'img/2-slide-3.png',
          text: 'Прокат автомобиля, трансфер'
        },
        {
          img: 'img/2-slide-4.png',
          text: 'Помощь в покупке недвижимости'
        },
        {
          img: 'img/2-slide-5.png',
          text: 'Персональные шопперы и стилисты'
        },
        {
          img: 'img/2-slide-6.png',
          text: 'Услуги переводчиков и гидов '
        },
      ]
    }
  },
  mounted(){
    document.querySelector('html').scrollIntoView({
      behavior: 'smooth'
    });
      console.log('created slider')
      //eslint-disable-next-line no-undef
      $('.sliderServices2').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        infinite: true,
      });
      //eslint-disable-next-line no-undef
      $('.sliderServices1').slick({
        dots: true,
        arrows: false,
        infinite: true,
        autoplay: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
        ]
      });
      },
  beforeUnmount() {
    console.log('unmount');
    //eslint-disable-next-line no-undef
    $('.sliderServices2').slick('unslick');
    //eslint-disable-next-line no-undef
    $('.sliderServices1').slick('unslick');

  }

}
</script>

<style>
</style>
